// import React from 'react';
import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perspiciatis laudantium eveniet numquam laboriosam neque ad aut quae quia nisi aspernatur excepturi tenetur sapiente reprehenderit magnam iste, dolores, atque odit beatae.</p>
      </header>
    </div>
  );
}

export default App;
